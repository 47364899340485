
import { defineComponent, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

// components
import InvitationSummary from "@/components/molecules/InvitationSummary.molecules.vue";

// composables
import useDateFormatter from "@/composables/dateFormatter";

// services
import tokenService from "@/services/token.service";

// interfaces
import { IHistoryDetail } from "@/interfaces/token";
import { IInvitationSummary, ITestSummary } from "@/interfaces/invitation";

export default defineComponent({
  components: { InvitationSummary },
  setup() {
    let state = reactive({
      historyData: {} as IHistoryDetail,
      invitationData: {} as IInvitationSummary,
    });

    const route = useRoute();
    onMounted(async () => {
      const historyId = Number(route.params.historyId);

      const { data } = await tokenService.getHistoryDetail(historyId);
      state.historyData = data;

      const { invoice } = data;
      state.invitationData = {
        total_participants: Number(invoice.total_participants),
        total_tests: Number(invoice.total_tests),
        total_token: Number(invoice.total_token),
        ended_at: invoice.ended_at as string,
        tests: invoice.bundles as unknown as ITestSummary[],
      };
    });

    const { formatDateTime } = useDateFormatter();

    return {
      state,
      formatDateTime,
    };
  },
});
