import httpClient from "./http-client";
import authHeader from "./auth-header";
import { AxiosRequestHeaders } from "axios";

const getHistory = async (
  page = 1,
  size = 10,
  status = "",
  type = "",
  sort = "created_at,desc"
): Promise<any> => {
  const params = {
    page,
    size,
    sort,
    status,
    type,
  };

  const { data } = await httpClient.get("/b-client/v1/token-history", {
    params,
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const getHistoryDetail = async (id: number): Promise<any> => {
  const { data } = await httpClient.get(`/b-client/v1/token-history/${id}`, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

export default {
  getHistory,
  getHistoryDetail,
};
