
import { defineComponent, PropType } from "vue";

// composables
import useDateFormatter from "@/composables/dateFormatter";

// interfaces
import { IInvitationSummary } from "@/interfaces/invitation";

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<IInvitationSummary>,
    },
  },
  setup() {
    const { formatDateTime } = useDateFormatter();

    return {
      formatDateTime,
    };
  },
});
